import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import { Button } from '@mui/material';
import Icon from 'components/Icon';

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  '& svg > path': {
    fill: theme.palette.icon.primary.color,
  },
}));

export const StyledButton = styledMui(Button)(({ theme, isSelected }) => ({
  display: 'flex',
  justifyContent: 'start',
  height: 70,
  width: 'calc(100% * (1/3) - 24px - 2px)',
  minWidth: 245,
  margin: 12,
  padding: '12px 14px',
  whiteSpace: 'nowrap',
  fontSize: 16,
  textTransform: 'none',
  color: theme.palette.text.primary,
  border: `1px solid ${isSelected ? theme.palette.icon.hover : theme.palette.sectionButton.border}`,
  borderRadius: 6,
  backgroundColor: theme.palette.sectionButton.background,
}));

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
`;

export const IconContainer = styledMui('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 44,
  height: 44,
  marginRight: 25,
  background: theme.palette.icon.container.background,
  borderRadius: 4,
}));

export const SectionTitle = styledMui('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 100,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: 24,
  lineHeight: '33px',
  color: theme.palette.text.title,
}));

export const Line = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  width: 30,
  margin: 20,
  borderTop: `1px solid ${theme.palette.input.disabled.text}`,
}));
