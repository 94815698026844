import React from "react";
import { isString } from "lodash";
import { styled as styledMui } from "@mui/material/styles";

import Store from "./Store";

export const StyledIcon = styledMui("Icon")(({ theme }) => ({
  height: 44,
  width: 32,
  svg: {
    path: {
      fill: theme.palette.icon.primary.color,
    }
  }
}));

const NoWrapper = styledMui("div")(
  ({ $flipHorizontal, rotateLeft, rotateRight }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: $flipHorizontal
      ? "rotate(180deg)"
      : rotateLeft
        ? "rotate(270deg)"
        : rotateRight
          ? "rotate(90deg)"
          : "unset",
  })
);

const Wrapper = styledMui(NoWrapper)(({ $opacity }) => ({
  width: 40,
  height: 40,
  opacity: $opacity || 1,
}));

const Icon = ({
  icon = "",
  className,
  noWrapper = false,
  fallback,
  flipHorizontal,
  rotateLeft,
  rotateRight,
  opacity,
  size,
  color,
  theme,
}) => {
  if (icon == null || icon === "") {
    return null;
  }
  const SourceImage = isString(icon) ? Store.getImage(icon, fallback) : icon;

  if (noWrapper) {
    return (
      <NoWrapper
        className={className}
        $flipHorizontal={flipHorizontal ? 1 : undefined}
        rotateLeft={rotateLeft ? 1 : undefined}
        rotateRight={rotateRight ? 1 : undefined}
      >
        <SourceImage
          theme={theme}
          size={size}
          opacity={opacity}
          color={color}
        />
      </NoWrapper>
    );
  }

  return (
    <Wrapper
      className={className}
      $opacity={opacity ? 1 : undefined}
    >
      <SourceImage theme={theme} size={size} opacity={opacity} color={color} fontSize={size} />
    </Wrapper>
  );
};

export default Icon;
