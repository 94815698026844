import React, { Fragment, useState, useEffect, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import PageLayout from "layouts/PageLayout";

import withPermission from "utils/hooks/withPermission";
import AuthService from "services/AuthService";
import { Button } from "components";
import ContactUsModal from "help/components/ContactUsModal";
import GlobalEcosystem from "help/pages/GlobalEcosystem";
import HelpModal from "onboarding/pages/HelpModal";
import Dashboard from "help/pages/Dashboard";
import MyCompany from "help/pages/MyCompany";
import Projects from "help/pages/Projects";
import Account from "help/pages/Account";
import Communication from "help/pages/Communication";
import SectionMenu from "./SectionMenu";

import {
  HeaderPanel,
  Title,
  ButtonContainer,
  Text,
  Root,
  ScrollBlock,
  ContactUsPanel,
} from "./styles";

const MyCompanyRoute = withPermission(MyCompany, "My Company", "help");
const AccountRoute = withPermission(Account, "Account", "help");
const DashboardRoute = withPermission(Dashboard, "Dashboard", "help");
const GlobalEcosystemRoute = withPermission(
  GlobalEcosystem,
  "Global Ecosystem",
  "help"
);
const ProjectsRoute = withPermission(Projects, "Projects", "help");
const CommunicationsRoute = withPermission(
  Communication,
  "Communications",
  "help"
);

const Help = () => {
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const ref = useRef(null);

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  const handleCloseOnboardingModal = () => {
    setIsOnboardingModalOpen(false);
    AuthService.updateOnboardConfig("help");
  };

  const scrollTo = (anchor) => {
    setTimeout(() => {
      anchor.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 0);
  };

  useEffect(() => {
    const isVisited = AuthService.checkSectionVisit("help");
    setIsOnboardingModalOpen(!isVisited);
  }, []);

  scrollTo(ref);

  const defaultRoute = AuthService.checkSectionVisit("help")
    ? "dashboard"
    : "/";

  return (
    <PageLayout>
      <Fragment key="title">
        <HeaderPanel>
          <ScrollBlock ref={ref} />
          <Title>Help</Title>
        </HeaderPanel>
      </Fragment>
      <Fragment key="content">
        <ContactUsPanel>
          <Text>Choose a category to quickly find the help you need.</Text>
          <ButtonContainer>
            Still have some questions?
            <Button
              small
              onClick={handleModalOpen}
              variant="contained"
              text="Contact Us"
              color="primary"
            />
          </ButtonContainer>
        </ContactUsPanel>
        <SectionMenu />
        <Root>
          <Routes>
            <Route exact path="/dashboard/*" element={<DashboardRoute />} />
            <Route exact path="/projects/*" element={<ProjectsRoute />} />
            <Route
              exact
              path="/global-ecosystem/*"
              element={<GlobalEcosystemRoute />}
            />
            <Route path="/account/*" element={<AccountRoute />} />
            <Route path="/my-company/*" element={<MyCompanyRoute />} />
            <Route
              path="/communications/*"
              element={<CommunicationsRoute />}
            />
            <Route
              path="*"
              element={<Navigate to={defaultRoute} replace />}
            />
          </Routes>
        </Root>
        {modalOpen && <ContactUsModal onClose={handleModalClose} />}
        {isOnboardingModalOpen && (
          <HelpModal
            open={isOnboardingModalOpen}
            onCancel={handleCloseOnboardingModal}
          />
        )}
      </Fragment>
    </PageLayout>
  );
};

export default Help;
