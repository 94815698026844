import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const Root = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 40,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontSize: 16,
  color: theme.palette.text.primary,
  width: '100%',
}));

export const Title = styled.div`
  margin: 10px 27px;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const TextSection = styled.div`
  display: inline;
  flex-direction: column;
  max-width: 650px;
  margin: 5px 27px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
`;

export const TextButton = styledMui('span')(({ theme }) => ({
  marginLeft: 5,
  cursor: 'pointer',
  color: theme.palette.sidebar.selected.icon.background,
  '&:hover': {
    color: theme.palette.icon.hover,
  },
}));

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  margin: 5px 27px;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;

  > li {
    display: list-item;
  }
`;

export const HighlightedText = styled.span`
  font-weight: 800;
  margin-right: 3px;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;
