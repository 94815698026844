import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { ResponsiveContainer } from 'recharts';

import Select from 'components/Select';

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
  max-width: ${({ maxWidth }) => (maxWidth || '500px')};
  max-height: ${({ maxHeight }) => (maxHeight || '300px')};
  margin: auto;
  padding-top: ${({ heightOffset }) => (heightOffset > 0 ? `${heightOffset}px` : '0')};
  ${({ alignTop }) => alignTop && `
    margin-top: 0;
    margin-bottom: auto;
  `}
`;

export const TooltipRoot = styledMui('div')(({ theme }) => ({
  zIndex: 10,
  pointerEvents: 'none',
  backgroundColor: theme.palette.chart.tooltip,
  padding: '1rem',
  borderRadius: 4,
  boxShadow: `0px 1px 15px ${theme.palette.backdrop}`,
}));

export const TooltipLabel = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '14px',
  color: theme.palette.text.title,
}));

export const TooltipInfo = styledMui('div')(({ theme }) => ({
  display: 'flex',
  marginTop: 5,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '14px',
  color: theme.palette.text.main,
}));

export const TooltipValueWrapper = styled.span`
  display: flex;
  border-right: 1px solid #818289;
  padding-right: 10px;
  margin-right: 10px;
`;

export const TooltipDataName = styled.span`
  white-space: nowrap;
`;

export const TooltipValue = styledMui('span')(({ theme }) => ({
  marginLeft: 6,
  whiteSpace: 'nowrap',
  color: theme.palette.sidebar.selected.icon.background,
}));

export const TooltipSeparator = styled.span`
  color: #818289;
  font-size: 12px;
  line-height: 14px;
`;

export const RightPanel = styled.div`
  position: relative;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  min-width: 194px;
  height: 100%;
  padding: 0;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const StyledSelect = styled(Select)`
  background: var(--sidebarBackground);
  && .MuiFilledInput-root {
    border-color: transparent;
  }
  & .MuiSelect-selectMenu {
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    color: var(--titleColor);
    background: var(--sidebarBackground);
    border-radius: 6px;
    padding: 8px 30px 6px 16px;
  }
`;

const getColor = (index, colors) => {
  return colors[index];
};

export const DataName = styled.div`
  min-width: 180px;
  position: relative;
  display: flex;
  align-items: center;
  font-family: Graphik LC;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--textColor);
  margin-bottom: 16px;

  &::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 4px;
    margin-right: 8px;
    background: ${({ colorIndex, colors }) => (getColor(colorIndex, colors))};
    border-radius: 2px;
  }
`;
