// import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect, Suspense } from "react";
import { Provider, useSelector } from "react-redux";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { BaseCSS, GridThemeProvider } from "styled-bootstrap-grid";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import CssBaseline from "@mui/material/CssBaseline";
import ThemeCssLoader from 'assets/styles/mui/ThemeLoader';


import store, { history } from "store";
import ModulesRoutes from "modules/routes";
// import AccountRoutes from "modules/account/routes";
import SubmitFeeProposalPage from "modules/SubmitFeeProposalPage";
// import PrivacyPolicyPage from "modules/PrivacyPolicyPage";
import Spinner from "components/Spinner";
import * as NotificationsSelectors from "notifications/store/notification.selectors";

import { getTheme } from "assets/styles/mui/theme";
import "assets/styles/index.pcss";
import { StyledToastContainer } from "./styles";
import 'react-toastify/dist/ReactToastify.css';

// if (typeof window !== 'undefined') {
//   injectStyle();
// }

// That Code will be splitted in separate chunks and will be loaded on demand
// import AccountRoutes from 'modules/account/routes';
// import SubmitFeeProposalPage from 'modules/SubmitFeeProposalPage';
// import PrivacyPolicyPage from 'modules/PrivacyPolicyPage';
const AccountRoutes = React.lazy(
  () => import(/* webpackChunkName: "Account" */ "modules/account/routes"),
  { fallback: <Spinner /> }
);
const PrivacyPolicyPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PrivacyPolicyPage" */
      "modules/PrivacyPolicyPage"
    ),
  { fallback: <Spinner /> }
);

const gridTheme = {
  container: {
    padding: 0,
  },
};

const matcher =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

function onUpdateColorScheme() {
  if (!matcher) return;
  if (matcher.matches) {
    lightSchemeIcon && lightSchemeIcon.remove();
    document.head.append(darkSchemeIcon);
  } else {
    document.head.append(lightSchemeIcon);
    darkSchemeIcon && darkSchemeIcon.remove();
  }
}

if (matcher) {
  matcher.addListener(onUpdateColorScheme);
}

function App() {
  const [loading, setLoading] = useState(true);
  const theme = useSelector(NotificationsSelectors.globalTheme);

  useEffect(() => {
    onUpdateColorScheme();
    setLoading(false);
  }, []);

  if (loading) return null;

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <GridThemeProvider gridTheme={gridTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledToastContainer className="testing-toast" />
          <CssBaseline />
          <ThemeCssLoader />
          <BaseCSS />
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route
                path="/submit-fee-proposal"
                element={<SubmitFeeProposalPage />}
              />
              <Route path="/account/*" element={<AccountRoutes />} />
              <Route path="*" element={<ModulesRoutes />} />
            </Routes>
          </Suspense>
        </LocalizationProvider>
      </GridThemeProvider>
    </ThemeProvider>
  );
}

function Root() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </Router>
    </Provider>
  );
}

export default Root;
