import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SubjectMenu from "help/components/SubjectMenu";
import { ACCOUNT_SUBJECTS } from "help/constants";

import Overview from "./Overview";

import { Root } from "../styles";

const Account = () => (
  <>
    <SubjectMenu section="account" subjects={ACCOUNT_SUBJECTS} />
    <Root>
      <Routes>
        <Route path="*" element={<Overview />} />
      </Routes>
    </Root>
  </>
);
export default Account;
