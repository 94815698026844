import { ModulePermissions } from 'types';
import { COMPANY_TYPES } from 'types/company';

export const CompanyTypeSideBar: { [key: string]: COMPANY_TYPES[] } = {
  Subscriptions: [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  Communications: [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  Projects: [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  Dashboard: [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  'Global Ecosystem': [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  'My Company': [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  'Custom Reports': [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  Library: [
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  Partners: [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
  Help: [
    COMPANY_TYPES.BROKER,
    COMPANY_TYPES.INSURANCE,
    COMPANY_TYPES.RISK,
    COMPANY_TYPES.NO_COMPANY,
  ],
};

export const sidebar: ModulePermissions = {
  Subscriptions: {
    Admin: {},
  },
  'Non-Subscribers': {
    Admin: {},
  },
  Communications: {
    Admin: {},
  },
  Projects: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    SiteContact: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
  Dashboard: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    SiteContact: {},
    RCCoordinator: {},
    RiskEngineer: {},
    ACCoordinator: {},
  },
  'Global Ecosystem': {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
  'My Company': {
    ICCoordinator: {},
    RCCoordinator: {},
  },
  'Custom Reports': {
    ICCoordinator: {},
  },
  Library: {
    ICCoordinator: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
  Partners: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    SiteContact: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
  Help: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    SiteContact: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
};
