import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SubjectMenu from "help/components/SubjectMenu";
import { MY_COMPANY_SUBJECTS } from "help/constants";

import Subscription from "./Subscription";
import TeamMembers from "./TeamMembers";
import CompanyInfo from "./CompanyInfo";
import Overview from "./Overview";
import Rates from "./Rates";

import { Root } from "../styles";

const MyCompany = () => (
  <>
    <SubjectMenu section="my-company" subjects={MY_COMPANY_SUBJECTS} />
    <Root>
      <Routes>
        <Route path="/overview" exact element={<Overview/>} />
        <Route
          path="/company-info"
          exact
          element={<CompanyInfo/>}
        />
        <Route
          path="/team-members"
          exact
          element={<TeamMembers/>}
        />
        <Route path="/rates" exact element={<Rates/>} />
        <Route
          path="/subscription"
          exact
          element={<Subscription/>}
        />
        <Route
          path="*"
          element={<Navigate to="overview" replace />}
        />
      </Routes>
    </Root>
  </>
);

export default MyCompany;
