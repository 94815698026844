import Logo from './Logo';
import LogoLight from './LogoLight';

import Arrow from './ui/Arrow';
import ArrowUp from './ui/ArrowUp';
import ArrowUpInCircle from './ui/ArrowUpInCircle';
import ArrowDown from './ui/ArrowDown';
import ArrowDouble from './ui/ArrowDouble';
import ArrowFilled from './ui/ArrowFilled';
import ArrowForward from './ui/ArrowForward';
import ArrowRight from './ui/ArrowRight';
import ChevronUp from './ui/ChevronUp';
import CheckMarkRound from './ui/CheckMarkRound';
import CheckMarkRoundFilled from './ui/CheckMarkRoundFilled';
import ChevronDown from './ui/ChevronDown';
import ChevronRight from './ui/ChevronRight';
import Cross from './ui/Cross';
import CrossSmall from './ui/CrossSmall';
import Delete from './ui/Delete';
import DeleteSmall from './ui/DeleteSmall';
import Dollar from './ui/Dollar';
import Download from './ui/Download';
import Edit from './ui/Edit';
import LogOut from './ui/LogOut';
import Account from './ui/Account';
import Plus from './ui/Plus';
import Printer from './ui/Printer';
import Warning from './ui/Warning';
import Reset from './ui/Reset';
import Image from './ui/Image';
import Video from './ui/Video';
import PaperClip from './ui/PaperClip';
import Address from './ui/Address';
import User from './ui/User';
import UserAdmin from './ui/UserAdmin';
import PrimaryUserAdmin from './ui/PrimaryUserAdmin';
import Phone from './ui/Phone';
import Mail from './ui/Mail';
import CvIcon from './ui/CvIcon';
import Policy from './ui/Policy';
import Filter from './ui/Filter';
import Comment from './ui/Comment';
import Share from './ui/Share';
import Complete from './ui/Complete';
import Company from './ui/Company';

import Dashboard from './sidebar/Dashboard';
import Projects from './sidebar/Projects';
import Administration from './sidebar/Administration';
import Email from './sidebar/Email';
import GlobalEcosystem from './sidebar/GlobalEcosystem';
import MyCompany from './sidebar/MyCompany';
import Library from './sidebar/Library';
import Subscriptions from './sidebar/Subscriptions';
import Help from './sidebar/Help';

import Notification from './notifications/Notification';
import NotificationNew from './notifications/NotificationNew';
import Accepted from './notifications/Accepted';
import Rejected from './notifications/Rejected';
import UnreadNotification from './notifications/UnreadNotification';
import UnreadAlert from './notifications/UnreadAlert';
import WarningNew from './notifications/WarningNew';
import OpenFullScreen from './notifications/OpenFullScreen';

import Search from './components/Search';
import Eye from './components/Eye';
import EyeClosed from './components/EyeClosed';
import ThreeDots from './components/ThreeDots';
import ThemeDark from './components/ThemeDark';
import ThemeLight from './components/ThemeLight';
import Calendar from './components/Calendar';
import Congrats from './components/Congrats';

import Details from './project/Details';
import Location from './project/Location';
import Notes from './project/Notes';
import Documents from './project/Documents';
import Contacts from './project/Contacts';
import Budget from './project/Budget';
import Receipt from './project/Receipt';
import Resources from './project/Resources';
import ReportNotes from './project/ReportNotes';
import Inspections from './project/Inspections';
import Recommendations from './project/Recommendations';
import Claims from './project/Claims';
import TimeAndExpenses from './project/TimeAndExpenses';
import Billing from './project/Billing';
import Analytics from './project/Analytics';
import Reports from './project/Reports';

import UploadFile from './documents/UploadFile';
import Folder from './documents/Folder';
import FolderEmpty from './documents/FolderEmpty';
import Locked from './documents/Locked';
import Unlocked from './documents/Unlocked';
import Unknown from './fileTypes/Unknown';
import Doc from './fileTypes/Doc';
import Jpg from './fileTypes/Jpg';
import Pdf from './fileTypes/Pdf';
import Txt from './fileTypes/Txt';
import Xls from './fileTypes/Xls';

import Report from './dashboard/Report';
import Var from '@mui/icons-material/Architecture';
import Groups from "@mui/icons-material/Groups";
import WaterDamageIcon from '@mui/icons-material/WaterDamageOutlined';

export const IMAGES = {
  account: Account,
  arrow: Arrow,
  arrowUp: ArrowUp,
  arrowUpInCircle: ArrowUpInCircle,
  arrowDown: ArrowDown,
  arrowDouble: ArrowDouble,
  arrowFilled: ArrowFilled,
  arrowForward: ArrowForward,
  arrowRight: ArrowRight,
  budget: Budget,
  cvIcon: CvIcon,
  edit: Edit,
  logo: Logo,
  logoDark: Logo,
  logoLight: LogoLight,
  themeDark: ThemeDark,
  themeLight: ThemeLight,
  warning: Warning,
  warningNew: WarningNew,
  openFullScreen: OpenFullScreen,
  dashboard: Dashboard,
  projects: Projects,
  administration: Administration,
  email: Email,
  notification: Notification,
  notificationNew: NotificationNew,
  accepted: Accepted,
  rejected: Rejected,
  unreadNotification: UnreadNotification,
  unreadAlert: UnreadAlert,
  calendar: Calendar,
  location: Location,
  details: Details,
  notes: Notes,
  documents: Documents,
  contacts: Contacts,
  feeProposal: Receipt,
  resources: Resources,
  'report-notes': ReportNotes,
  // var: Var,
  inspections: Inspections,
  recommendations: Recommendations,
  claims: Claims,
  expenses: TimeAndExpenses,
  billing: Billing,
  search: Search,
  share: Share,
  cross: Cross,
  crossSmall: CrossSmall,
  delete: Delete,
  eye: Eye,
  eyeClosed: EyeClosed,
  deleteSmall: DeleteSmall,
  threeDots: ThreeDots,
  logOut: LogOut,
  folder: Folder,
  folderEmpty: FolderEmpty,
  plus: Plus,
  checkMarkRound: CheckMarkRound,
  checkMarkRoundFilled: CheckMarkRoundFilled,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  chevronRight: ChevronRight,
  download: Download,
  locked: Locked,
  unlocked: Unlocked,
  unknown: Unknown,
  address: Address,
  filter: Filter,
  phone: Phone,
  user: User,
  userAdmin: UserAdmin,
  primaryUserAdmin: PrimaryUserAdmin,
  mail: Mail,
  reset: Reset,
  doc: Doc,
  jpg: Jpg,
  pdf: Pdf,
  txt: Txt,
  xls: Xls,
  image: Image,
  video: Video,
  uploadFile: UploadFile,
  policy: Policy,
  paperClip: PaperClip,
  printer: Printer,
  report: Report,
  analytics: Analytics,
  reports: Reports,
  globalEcosystem: GlobalEcosystem,
  myCompany: MyCompany,
  library: Library,
  comment: Comment,
  complete: Complete,
  subscriptions: Subscriptions,
  dollar: Dollar,
  company: Company,
  help: Help,
  congrats: Congrats,
  var: Var,
  partners: Groups,
  devices: WaterDamageIcon,
};

class Store {
  getImage = (icon, fallbackIcon) => {
    if (IMAGES[icon]) return IMAGES[icon];

    if (fallbackIcon && IMAGES[fallbackIcon]) {
      return IMAGES[fallbackIcon];
    }

    return IMAGES.warning;
  }
}

export default new Store();
