import { styled as styledMui } from '@mui/material/styles';
import { ListItem, List } from '@mui/material';

export const StyledListItem = styledMui(ListItem)(({ theme }) => ({
  '&.MuiListItem-root': {
    justifyContent: 'space-between',
    height: 40,
    borderRadius: 4,
    color: theme.palette.text.light,
  },
  '& .MuiTypography-body1': {
    fontSize: 16,
    fontWeight: 500,
  },
  '&.MuiListItem-button:hover': {
    backgroundColor: theme.palette.input.hover.color,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.icon.container.background,
    color: theme.palette.text.primary,
  },
  '&.MuiListItem-gutters': {
    padding: '10px 16px',
  },
  '> .MuiListItemIcon-root': {
    justifyContent: 'center',
    minWidth: 40,
  },
}));

export const StyledList = styledMui(List)(({ theme }) => ({
  minHeight: 440,
  minWidth: 292,
  marginRight: 30,
  paddingRight: 24,
  borderRight: `1px solid ${theme.palette.notifications.item.borderBottom}`,
}));

export const Title = styledMui('div')(({ theme }) => ({
  padding: '0 16px 20px',
  letterSpacing: '0.06em',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
}));
