import React from 'react';
import { useLocation } from 'react-router-dom';

import { Root, Title, Descr, StyledIcon } from './styles';
import { useSelector } from 'react-redux';
import * as NotificationsSelectors from "notifications/store/notification.selectors";
import { getTheme } from 'assets/styles/mui/theme';

interface NoContentProps {
  title: string;
  descr: string | React.ReactNode;
  customIcon?: string;
  className?: string;
  iconSize?: string;
  iconOpacity?: number;
  children?: React.ReactNode;
}

const NoContent = ({ title, descr, customIcon, className, iconSize, iconOpacity, children }: NoContentProps) => {
  const theme = useSelector(NotificationsSelectors.globalTheme);
  const location = useLocation();
  const tab = location.pathname.split('/').reverse()[0];
  const icon = tab === 'projects' ? 'details' : tab;

  return (
    <Root className={className}>
      <StyledIcon icon={customIcon || icon} size={iconSize} opacity={iconOpacity} noWrapper color={undefined} fallback={undefined} rotateLeft={undefined} flipHorizontal={undefined} rotateRight={undefined} theme={getTheme(theme)} className={undefined} />
      <Title>{title}</Title>
      <Descr>{descr}</Descr>
      {children}
    </Root>
  );
};

export default NoContent;
