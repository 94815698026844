import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SubjectMenu from "help/components/SubjectMenu";
import { GE_SUBJECTS } from "help/constants";

import Overview from "./Overview";
import AdvancedDetails from "./AdvancedDetails";

import { Root } from "../styles";

const GlobalEcosystem = () => (
  <>
    <SubjectMenu section="global-ecosystem" subjects={GE_SUBJECTS} />
    <Root>
      <Routes>
        <Route
          path="/overview"
          exact
          element={<Overview />}
        />
        <Route
          path="/advanced-details"
          exact
          element={<AdvancedDetails />}
        />
        <Route
          path="*"
          element={<Navigate to="overview" replace />}
        />
      </Routes>
    </Root>
  </>
);

export default GlobalEcosystem;
