import { styled as styledMui } from '@mui/material/styles';

import { Button, Icon } from 'components';

export const StyledButton = styledMui(Button)(({ theme }) => ({
  marginLeft: 15,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 16,
  lineHeight: '160%',
  letterSpacing: '0.16px',
  color: theme.palette.text.title,
  '& > :last-child': {
    marginLeft: 2,
  },
  '&:hover': {
    color: theme.palette.button.primary.color,
    '& svg path': {
      fill: theme.palette.button.primary.color,
    },
  },
}));

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  marginLeft: 10,
  '& svg path': {
    fill: theme.palette.icon.primary.color,
  },
}));
