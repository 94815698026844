import React, { Fragment, useState } from "react";

import PageLayout from "layouts/PageLayout";
import {
  ArrowUp,
  HeaderPanel,
  IconWrapper,
  Line,
  Title,
  TitleButton,
  TitleText,
} from "./styles";
import CarouselExample from "modules/partners/components/Carousel";

const Partners = () => {

  return (
    <>
      <PageLayout>
        <div>Hi</div>
        <Fragment key="title">
          <HeaderPanel>
            <Title>Partners</Title>
          </HeaderPanel>
        </Fragment>
        <Fragment key="content">
          <CarouselExample />
        </Fragment>
      </PageLayout>
    </>
  );
};

export default Partners;
