import { createTheme } from '@mui/material/styles';

// declare module '@mui/material/styles' {
//   interface Theme {
//     status: {
//       danger: string;
//     };
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

const lightModePalette = {
  mode: 'light',
  toast: {
    error: '#EB5757',
    success: '#3AC09D',
    alert: '#F2C94C',
    info: '#059FDE',
  },
  stepper: {
    iconRoot: '#F4F5FA',
    activeIcon: '#059FDE',
    completedIcon: '#3AC09D',
    buttonTitleHover: '#E6E9F4',
  },
  text: {
    main: '#0A2463',
    contrastText: '#142426',
    light: 'rgba(10, 36, 99, 0.6)',
    title: '#0A2463',
    tooltip: '#0A2463',
  },
  primary: {
    main: '#96CEF6',
    contrastText: '#142426',
  },
  secondary: {
    main: '#73778C',
    contrastText: 'rgba(255, 255, 255, 0.87);',
  },
  error: {
    main: '#FA928D',
    contrastText: '#FFF',
  },
  map: {
    baseMapId: '63c47b7177f946b49902c24129b87252',
    outline: '#FFF',
  },
  background: {
    paper: '#F4F5FA',
    default: '#FFFFFF',
    workspace: '#F4F5FA',
    sidebar: '#064789',
  },
  navbar: {
    tab: {
      // navTabsBorderColor
      border: '#E6E9F4',
      color: '#0A2463',
      active: '#059FDE',
      disabled: 'rgba(10, 36, 99, 0.3)',
      label: {
        color: 'rgba(10, 36, 99, 0.4)',
        active: '#0A2463',
        hover: 'rgba(10, 36, 99, 1)',
        disabled: 'rgba(10, 36, 99, 0.3)',
      },

    },
  },
  icon: {
    primary: {
      color: '#0A2463',
      disabled: 'rgba(10, 36, 99, 0.2);',
    },
    container: {
      background: '#E6E9F4',
    },
    hover: '#1FBAFA',
  },
  border: {
    light: 'rgba(10,36,99,0.18)',
  },
  table: {
    highlight: 'rgba(244, 245, 250, 0.7)',
    header: {
      color: '#0A2463',
      background: '#F4F5FA',
    },
    row: {
      color: '#0A2463',
    },
  },
  button: {
    color: '#F4F5FA',
    text: '#0A2463',
    hover: '#E6E9F4',
    active: '#CACFE7',
    disabled: {
      color: '#F4F5FA',
      text: 'rgba(255, 255, 255, 0.38)',
    },
    primary: {
      color: '#059fde',
      text: '#FFFFFF',
      hover: {
        color: '#1FBAFA',
        text: '#FFFFFF',
      },
      active: '#047DAF',
      disabled: {
        color: 'rgba(5, 159, 222, 0.3)',
        text: 'rgba(255, 255, 255, 0.38)',
      },
    },
    secondary: {
      color: '#EB5757',
      text: '#FFFFFF',
      hover: {
        color: '#F18D8D',
      },
      active: '#D82727',
      disabled: {
        color: 'rgba(235, 87, 87, 0.6)',
        text: 'rgba(255, 255, 255, 0.38)',
      },
    },
    widget: {
      color: '#059FDE',
    },
  },
  input: {
    color: '#FFFFFF',
    text: '#0A2463',
    hover: {
      color: '#F4F5FA',
    },
    focused: {
      color: '#FFFFFF',
      border: '#0A2463',
    },
    disabled: {
      color: '#FFFFFF',
      text: 'rgba(10,36,99,0.3)',
      border: '#E6E9F4',
    },
    readonly: {
      border: '#E6E9F4',
    },
    border: {
      color: '#E6E9F4',
      light: '',
    },
    search: {
      color: '#FFFFFF',
      border: {
        color: '#E6E9F4',
      },
      icon: {
        color: '#0A2463',
      },
      hover: {
        color: '#F4F5FA',
        background: '#FFF',
      },
      disabled: {
        color: '#FFFFFF',
      },
      borderless: {
        color: '#FFFFFF',
      },
    },
    select: {
      topBorder: '#E6E9F4',
    },
    checkbox: {
      color: 'gray',
    },
  },
  sidebar: {
    toggle: '#FFFFFF',
    text: 'rgba(255, 255, 255, 0.87)',
    hover: 'rgba(255, 255, 255, 0.2)',
    selected: {
      icon: {
        color: '#FFF',
        background: '#059FDE',
      },
      backgroundColor: '#f4f5fa',
      text: '#0A2463',
    },
  },
  accordion: {
    header: '#F4F5FA',
    box: {
      shadow: 'rgba(235, 236, 243, 0.6)',
    },
  },
  notifications: {
    color: '#FFF',
    item: {
      borderBottom: 'rgba(10, 36, 99, 0.2)',
    },
    info: {
      background: '#F4F5FA',
    },
    dayTitle: '#F4F5FA',
  },
  progressBar: {
    background: 'rgba(115, 119, 140, 1)',
  },
  backdrop: 'rgba(115, 119, 140, 0.4)',
  sectionButton: {
    background: '#FFF',
    border: '#E6E9F4',
  },
  chart: {
    tooltip: '#FFF',
  },
};

const darkModePalette = {
  mode: 'dark',
  toast: {
    error: '#FA928D',
    success: '#96F6B8',
    alert: '#F6DB81',
    info: '#96CEF6',
  },
  text: {
    main: 'rgba(255, 255, 255, 0.87)',
    light: 'rgba(255, 255, 255, 0.6)',
    title: '#FFFFFF',
    tooltip: '#73778C',
  },

  // --stepperIconRoot: #73778C;
  // --stepperActiveIcon: #96CEF6;
  // --stepperCompletedIcon: #969EF6;
  // --stepperButtonTitleHover: #3e404c;
  stepper: {
    iconRoot: '#73778C',
    activeIcon: '#96CEF6',
    completedIcon: '#969EF6',
    buttonTitleHover: '#3e404c',
  },
  primary: {
    main: '#96CEF6',
    contrastText: '#142426',
    light: 'rgba(255, 255, 255, 0.6)',
  },
  secondary: {
    main: '#73778C',
    contrastText: 'rgba(255, 255, 255, 0.87);',
  },
  error: {
    main: '#FA928D',
    contrastText: '#FFF',
  },
  map: {
    baseMapId: '5c4129f64f4b480bb5f29638a7766dc1',
    outline: '#343640',
  },
  background: {
    paper: '#343640',
    // pageLayoutBackground
    default: '#1B1C21',
    workspace: '#121212',
    sidebar: '#1B1C21',
  },
  navbar: {
    tab: {
      border: 'rgba(255, 255, 255, 0.15)',
      color: '#fff',
      active: '#96CEF6',
      disabled: 'rgba(255, 255, 255, 0.38)',
      label: {
        color: 'rgba(255, 255, 255, 0.45)',
        active: 'rgba(255, 255, 255, 0.87)',
        hover: 'rgba(255, 255, 255, 0.87)',
        disabled: 'rgba(255, 255, 255, 0.45)',
      },
    },
  },
  table: {
    highlight: 'rgba(115, 119, 140, 0.2)',
    header: {
      color: 'rgba(255, 255, 255, 0.87)',
      background: '#343640',
    },
    row: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  icon: {
    primary: {
      color: 'rgba(255, 255, 255, 0.87)',
      disabled: 'rgba(150, 206, 246, 0.6)',
    },
    container: {
      background: '#343640',
      // --iconContainerBackground: #E6E9F4;
    },
    hover: '#BEE0F9',
  },
  border: {
    light: 'rgba(255, 255, 255, 0.3)',
  },
  button: {
    color: '#73778C',
    text: 'rgba(255, 255, 255, 0.87)',
    hover: '#9CA2BF',
    active: '#575A6B',
    disabled: {
      color: '#494C59',
      text: 'rgba(255, 255, 255, 0.38)',
    },
    primary: {
      color: '#96CEF6',
      text: '#142426',
      hover: {
        color: '#BEE0F9',
        text: '#142426',
      },
      active: '#72BDF3',
      disabled: {
        color: 'rgba(150, 206, 246, 0.6)',
        text: 'rgba(255, 255, 255, 0.38)',
      },
    },
    secondary: {
      color: '#FA928D',
      text: '#FFFFFF',
      hover: {
        color: '#F97C76',
      },
      active: '#CC7773',
      disabled: {
        color: '#CC7773',
        text: 'rgba(255, 255, 255, 0.87)',
      },
    },
    widget: {
      color: '#96CEF6',
    },
  },
  input: {
    color: '#343640',
    text: 'rgba(255,255,255,0.87)',
    hover: {
      color: '#3e404c',
    },
    focused: {
      color: '#343640',
      border: '#96CEF6',
    },
    disabled: {
      color: 'rgba(52, 54, 64, 0.7)',
      text: 'rgba(255, 255, 255, 0.5)',
      border: 'transparent',
    },
    readonly: {
      border: 'rgba(255, 255, 255, 0.24)',
    },
    border: {
      color: 'transparent',
      light: '',
    },
    search: {
      color: '#2A2B33',
      border: {
        color: 'transparent',
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.6)',
      },
      hover: {
        color: 'rgba(255, 255, 255, 0.6)',
        background: '#3e404c',
      },
      disabled: {
        color: 'rgba(52, 54, 64, 0.7)',
      },
      borderless: {
        color: '#1B1C21',
      },
    },
    select: {
      topBorder: '#73778C',
    },
    checkbox: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  sidebar: {
    toggle: '#3E414D',
    text: 'rgba(255, 255, 255, 0.45)',
    hover: '#292B32',
    selected: {
      icon: {
        color: 'rgb(20, 36, 38)',
        background: '#96CEF6',
      },
      backgroundColor: '#343640',
      text: 'rgba(255, 255, 255, 0.87)',
    },
  },
  accordion: {
    header: '#454754',
    box: {
      shadow: 'rgba(235, 236, 243, 0)',
    },
  },
  notifications: {
    color: '#1D1E24',
    item: {
      borderBottom: 'rgba(255, 255, 255, 0.2)',
    },
    info: {
      background: '#32333e',
    },
    dayTitle: 'rgba(52, 54, 64, 0.5)',
  },
  progressBar: {
    background: 'rgba(115, 119, 140, 1)',
  },
  backdrop: 'rgba(0, 0, 0, 0.4)',
  sectionButton: {
    background: 'transparent',
    border: 'rgba(255, 255, 255, 0.24)',
  },
  chart: {
    tooltip: '#73778C',
  },
};

export const getTheme = (mode = 'dark') => {
  const isDark = mode === 'dark';
  const palette = isDark ? darkModePalette : lightModePalette;
  const scrollBarStyle = {
    scrollbarWidth: 'thin',
    scrollbarColor: isDark
      ? 'rgba(255, 255, 255, 0.38) transparent'
      : 'rgba(156, 162, 191, 0.38) #FFFFFF',

    '&::-webkit-scrollbar-track': {
      border: '0 #FFFFFF',
      borderRadius: '1',
      background: isDark ? 'transparent;' : '#FFFFFF;',
      '&:hover': {
        background: isDark ? 'transparent;' : '#FFFFFF;',
      },
      '&:active': {
        background: isDark ? 'transparent;' : '#FFFFFF;',
      },
    },
    '&::-webkit-scrollbar-thumb': {
      border: '0 #fff',
      borderRadius: '1',
      background: isDark ? 'rgba(255, 255, 255, 0.38);' : '#E6E9F4;',
      '&:hover': {
        background: isDark ? 'rgba(255, 255, 255, 0.38);' : '#9BA1BF;',
      },
      '&:active': {
        background: isDark ? 'rgba(255, 255, 255, 0.38);' : '#9BA1BF;',
      },
    },
  };

  return createTheme({
    palette,
    typography: {
      fontFamily: 'Graphik LC',
      h1: {
        fontFamily: 'Avenir',
      },
      h2: {
        fontFamily: 'Avenir',
      },
      h3: {
        fontFamily: 'Avenir',
      },
      h4: {
        fontFamily: 'Avenir',
      },
      h5: {
        fontFamily: 'Avenir',
      },
      h6: {
        fontFamily: 'Avenir',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            color: palette.text.main,
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            // backgroundColor: '#bbdefb',
            // borderRadius: '2px',
            // // borderWidth: '1px',
            // // borderColor: '',
            // border: '4px solid #2196f3',
            // // backgroundColor: '#0d47a1',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: 40,
          },
          containedPrimary: {
            opacity: 1,
            backgroundColor: palette.button.primary.color,
            color: palette.button.primary.text,
            '&:hover': {
              backgroundColor: palette.button.primary.hover.color,
              color: palette.button.primary.hover.text,
            },
            '&:active': {
              backgroundColor: palette.button.primary.active,
            },
            '&:disabled': {
              backgroundColor: palette.button.primary.disabled.color,
              color: palette.button.primary.disabled.text,
            },
          },
          containedSecondary: {
            opacity: 1,
            backgroundColor: palette.button.color,
            color: palette.button.text,
            '&:hover': {
              backgroundColor: palette.button.hover.color,
            },
            '&:active': {
              backgroundColor: palette.button.active,
            },
            '&:disabled': {
              backgroundColor: palette.button.disabled.color,
              color: palette.button.disabled.text,
            },
          },
          containedError: {
            opacity: 1,
            backgroundColor: palette.button.secondary.color,
            color: palette.button.secondary.text,
            '&:hover': {
              backgroundColor: palette.button.secondary.hover.color,
            },
            '&:active': {
              backgroundColor: palette.button.secondary.active,
            },
            '&:disabled': {
              backgroundColor: palette.button.secondary.disabled.color,
              color: palette.button.secondary.disabled.text,
            },
          },
          contained: {
            opacity: 1,
            backgroundColor: palette.button.color,
            color: palette.button.text,
            '&:hover': {
              backgroundColor: palette.button.hover,
            },
            '&:active': {
              backgroundColor: palette.button.active,
            },
            '&:disabled': {
              backgroundColor: palette.button.disabled.color,
              color: palette.button.disabled.text,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            height: 48,
            fontFamily: 'Graphik LC',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: '18px',
            letterSpacing: 0.16,
            MozAppearance: 'textfield',
            '> input': {
              padding: '22px 16px 6px',
            },
            '&.Mui-disabled > input': {
              color: `${palette.input.disabled.text} !important`,
              '-webkit-text-fill-color': `${palette.input.disabled.text} !important`,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.text.main,
            opacity: 0.55,
            '&.Mui-focused': {
              color: palette.text.main,
            },
            fontSize: 14,
          },
          filled: {
            label: {
            },
            transform: 'translate(16px, 15px)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(17.4px, 7px)',
              fontFamily: 'Graphik LC',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: 10,
              lineHeight: '18px',
              letterSpacing: 0.16,
              // color: palette.text.main,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            position: 'absolute',
            top: 50,
            left: 0,
            fontFamily: 'Graphik LC',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 10,
            lineHeight: '15px',
            letterSpacing: 0.16,
            color: palette.error.main,
          },
          contained: {
            margin: '0 16px',
          },
          input: {
            padding: '22px 16px 6px',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            ...scrollBarStyle,
            padding: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
      },
      MuiPickersYearSelection: {
        styleOverrides: {
          container: scrollBarStyle,
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 0,
          },
          root: scrollBarStyle,
          elevation: {
            boxShadow: 'none',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: '2px',
            padding: '8px 12px',
            fontFamily: 'Graphik LC',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '16px',
            color: '#FFFFFF',
            backgroundColor: isDark ? '#73778C' : '#0A2463',
          },
          arrow: {
            color: isDark ? '#73778C' : '#0A2463',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          container: {
            minHeight: 40,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            maxHeight: 400,
            paddingTop: 0,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            background: 'none',
            ':before': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
            minHeight: 0,
            background: palette.background.paper,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            height: 40,
            '[data-focus="true"]': {
              backgroundColor: 'rgba(115, 119, 140, 0.5)',
            },
          },
        },
      },
    },
  });
};
