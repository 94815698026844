
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Partners from "./pages/Partners";

const PartnersRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Partners />} />
      <Route path="*" element={<Navigate to="/partners" replace />} />
    </Routes>
  );
};

export default PartnersRoutes;
