import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import withPermission from "utils/hooks/withPermission";

import SubjectMenu from "help/components/SubjectMenu";
import AuthService, { ROLES_IDS } from "services/AuthService";
import { getProjectSubjectsByRole } from "help/constants";
import DocumentsSection from "help/pages/Projects/components/DocumentsSection";
import ContactsSection from "help/pages/Projects/components/ContactsSection";

import Overview from "./Overview";
import SCOverview from "./SCOverview";
import ProjectDetailsAndLocation from "./ProjectDetailsAndLocation";
import DocumentsAndContacts from "./DocumentsAndContacts";
import DocumentsAndNotes from "./DocumentsAndNotes";
import BudgetAndFeeProposal from "./BudgetAndFeeProposal";
import ContactsAndResources from "./ContactsAndResources";
import RecommendationsAndClaimsAndAnalytics from "./RecommendAndClaimsAndAnalytics";
import ReportNotesAndRecommendationsAndClaims from "./ReportNotesAndRecommendAndClaims";
import InspectionsAndTimeAndExpenses from "./InspectionsAndTimeAndExpenses";
import AnalyticsAndReports from "./AnalyticsAndReports";

import { Root } from "../styles";

const ProjectDetailsAndLocationRoute = withPermission(
  ProjectDetailsAndLocation,
  "location",
  "projects"
);
const DocumentsAndNotesRoute = withPermission(
  DocumentsAndNotes,
  "notes",
  "projects"
);
const BudgetAndFeeProposalRoute = withPermission(
  BudgetAndFeeProposal,
  "budget",
  "projects"
);
const ContactsAndResourcesRoute = withPermission(
  ContactsAndResources,
  "resources",
  "projects"
);
const RecommendationsAndClaimsAndAnalyticsRoute = withPermission(
  RecommendationsAndClaimsAndAnalytics,
  "claims",
  "projects"
);
const InspectionsAndTimeAndExpensesRoute = withPermission(
  InspectionsAndTimeAndExpenses,
  "expenses",
  "projects"
);
const ContactsRoute = withPermission(ContactsSection, "contacts", "projects");
const DocumentsRoute = withPermission(
  DocumentsSection,
  "documents",
  "projects"
);
const ReportNotesAndRecommendationsAndClaimsRoute = withPermission(
  ReportNotesAndRecommendationsAndClaims,
  "report-notes",
  "projects"
);
const AnalyticsAndReportsRoute = withPermission(
  AnalyticsAndReports,
  "reports",
  "projects"
);
const DocumentsAndContactsRoute = withPermission(
  DocumentsAndContacts,
  "contacts",
  "projects"
);

const Projects = () => {
  const { role } = AuthService.getUser();
  const subjects = getProjectSubjectsByRole(role);

  const isSC = role === ROLES_IDS.SiteContact;

  return (
    <>
      <SubjectMenu section="projects" subjects={subjects} />
      <Root>
        <Routes>
          <Route
            path="/overview"
            exact
            element={isSC ? <SCOverview /> : <Overview />}
          />
          <Route
            path="/details-location"
            exact
            element={<ProjectDetailsAndLocationRoute />}
          />
          <Route
            path="/documents-notes"
            exact
            element={<DocumentsAndNotesRoute />}
          />
          <Route
            path="/budget-fee-proposal"
            exact
            element={<BudgetAndFeeProposalRoute />}
          />
          <Route
            path="/contacts-resources"
            exact
            element={<ContactsAndResourcesRoute />}
          />
          <Route
            path="/recommendations-claims-analytics"
            exact
            element={<RecommendationsAndClaimsAndAnalyticsRoute />}
          />
          <Route
            path="/inspections-time-and-expenses"
            exact
            element={<InspectionsAndTimeAndExpensesRoute />}
          />
          <Route
            path="/report-notes-recommendations-claims"
            exact
            element={<ReportNotesAndRecommendationsAndClaimsRoute />}
          />
          <Route
            path="/analytics-reports"
            exact
            element={<AnalyticsAndReportsRoute />}
          />
          <Route
            path="/documents-contacts"
            exact
            element={<DocumentsAndContactsRoute />}
          />
          <Route
            path="/documents"
            exact
            element={<DocumentsRoute />}
          />
          <Route
            path="/contacts"
            exact
            element={<ContactsRoute />}
          />

          <Route
            path="*"
            element={<Navigate to="overview" replace />}
          />
        </Routes>
      </Root>
    </>
  );
};
export default Projects;
