import React, { useState } from "react";
import { Box, Typography, Button, IconButton, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import BrickEyeDark from "public/img/partners/brickeye/dark.png";
import BrickEyeLight from "public/img/partners/brickeye/light.png";
import Monarch from "public/img/partners/monarch.svg";
import { useTheme } from "@emotion/react";

const itemsToDisplay = (isDarkMode) => [
  {
    title:
      "Brickeye and iMitig8riskTM Announce Strategic Partnership to Enhance Assessment and Mitigation of Water Damage and Concrete Risks in Construction",
    description:
      "TORONTO, October 28, 2024 – Brickeye, a leading construction IoT and data analytics technology company, and iMitig8riskTM, an expert in risk engineering and loss control insurance technology, are proud to announce a strategic partnership that will transform how construction risks are assessed and mitigated. The partnership is focused on mitigating water damage and concrete defects on major construction projects, helping insureds and insurers reduce claim frequency and severity and bend the cost curve on builders risk insurance. <br /> <br /> <a href='https://www.imitig8risk.com/press-release-1'>Read More</a>",
    image: isDarkMode ? BrickEyeDark : BrickEyeLight,
    link: "brickeye.com",
    hasLightMode: true,
  },
  {
    title: "Monarch",
    description:
      "Monarch's staff of experienced broadcast meteorologists, Certified Consulting Meteorologists (CCM) and data scientists, is uniquely suited to provide meteorological and climate services to professionals looking to mitigate their risk or better yet, reap its rewards. Founded by renowned broadcast meteorologists Crystal Egger and Kathryn Prociv.",
    image: Monarch,
    link: "monarchweather.com",
  },
];

const Slide = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "400px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  padding: theme.spacing(4),
  color: theme.palette.common.white,
  textAlign: "left",
}));

const Arrow = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  color: theme.palette.text.contrastText,
}));

const Indicators = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  alignItems: "center",
}));

const IndicatorDot = styled(Box)(({ theme, active }) => ({
  width: active ? "12px" : "8px",
  height: active ? "12px" : "8px",
  borderRadius: "50%",
  backgroundColor: active
    ? theme.palette.primary.main
    : theme.palette.grey[500],
  transition: "all 0.3s ease",
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
}));

const Image = styled("img")(({ theme, hasLightMode }) => ({
  height: 300,
  width: 300,
  backgroundColor: hasLightMode ? undefined : theme.palette.text.contrastText,
  padding: 30,
  borderRadius: 8,
}));

const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === "light" ? "black" : theme.palette.primary.main,
  textDecoration: "none",
  textTransform: "lowercase",
  fontWeight: "bolder",
  fontSize: "1rem",
  paddingLeft: 0,
}));

const CarouselExample = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };
  const items = itemsToDisplay(isDarkMode);

  return (
    <Box position="relative" width="100%" margin="auto" maxWidth={1200}>
      <Slide style={{ backgroundImage: `transparent`, margin: 30 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid item xs={4}>
            <Image
              hasLightMode={items[currentIndex].hasLightMode}
              src={items[currentIndex].image}
              alt={`${items[currentIndex].title}_logo`}
            />
          </Grid>
          <Grid item xs={8}>
            <Text variant="h5" gutterBottom sx={{
              color: isDarkMode ? undefined : "black",
            }}>
              {items[currentIndex].title}
            </Text>
            <Text
              variant="body1"
              gutterBottom
              dangerouslySetInnerHTML={{
                __html: items[currentIndex].description,
              }}
            />
            <LinkButton
              variant="bold"
              color="primary"
              href={`https://${items[currentIndex].link}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {items[currentIndex].link}
            </LinkButton>
          </Grid>
        </Grid>
      </Slide>
      <Arrow
        onClick={handlePrev}
        sx={{
          left: 16,
        }}
      >
        <ArrowBack />
      </Arrow>
      <Arrow
        onClick={handleNext}
        sx={{
          right: 16,
        }}
      >
        <ArrowForward />
      </Arrow>
      <Indicators>
        {items.map((_, index) => (
          <IndicatorDot
            key={index}
            active={index === currentIndex}
            onClick={() => {
              setCurrentIndex(index);
            }}
          />
        ))}
      </Indicators>
    </Box>
  );
};

export default CarouselExample;
