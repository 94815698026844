import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SubjectMenu from "help/components/SubjectMenu";
import { DASHBOARD_SUBJECTS } from "help/constants";

import DailyTools from "./DailyTools";
import Overview from "./Overview";

import { Root } from "../styles";

const Dashboard = () => {
  return (
    <>
      <SubjectMenu section="dashboard" subjects={DASHBOARD_SUBJECTS} />
      <Root>
        <Routes>
          <Route path="overview" exact element={<Overview />} />
          <Route path="tools" exact element={<DailyTools />} />
          <Route
            path="*"
            element={<Navigate to="overview" replace />}
          />
        </Routes>
      </Root>
    </>
  );
}

export default Dashboard;
