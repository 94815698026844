import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

function ThemeCssLoader() {
  const theme = useTheme();

  useEffect(() => {
    const { head } = document;
    const linkId = 'theme-css';
    let linkTag = document.getElementById(linkId);

    if (!linkTag) {
      linkTag = document.createElement('link');
      linkTag.id = linkId;
      linkTag.rel = 'stylesheet';
      head.appendChild(linkTag);
    }
    // Set the href to the appropriate file based on mode
    linkTag.href = theme.palette.mode === 'dark' ? '/css/dark.css' : '/css/light.css';
    console.log(linkTag.href);
  }, [theme.palette.mode]);

  return null;
}

export default ThemeCssLoader;
