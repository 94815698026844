import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { ListItem, List } from '@mui/material';

export const StyledListItem = styledMui(ListItem)(({ theme, isHorizontal, disabledWithTooltip }) => ({
  '&.MuiListItem-root': {
    justifyContent: 'center',
    height: 40,
    maxWidth: 190,
    borderRadius: 4,
    color: theme.palette.text.light,
    ...(isHorizontal && {
      justifyContent: 'center',
    }),
    ...(disabledWithTooltip && {
      color: theme.palette.text.light,
      opacity: 0.6,
    }),
    '.MuiListItemText-root': {
      flexWrap: 'nowrap',
      flexGrow: 0,
    },
  },
  '& .MuiTypography-body1': {
    fontSize: 16,
    fontWeight: 500,
  },
  '&.MuiListItem-button:hover': {
    backgroundColor: theme.palette.input.hover.color,
    ...(disabledWithTooltip && {
      backgroundColor: 'transparent',
    }),
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.input.hover.color,
    color: theme.palette.text.main,
    ...(disabledWithTooltip && {
      backgroundColor: 'transparent',
    }),
  },
  '&.MuiListItem-gutters': {
    padding: '10px 16px',
  },
  '> .MuiListItemIcon-root': {
    minWidth: 40,
    justifyContent: 'center',
  },
}));

export const StyledList = styledMui(List)(({ isHorizontal, theme }) => ({
  minWidth: 220,
  paddingRight: 24,
  marginBottom: 24,
  ...(isHorizontal && {
    display: 'flex',
    paddingBottom: 5,
    borderBottom: `1px solid ${theme.palette.notifications.item.borderBottom}`,
  }),
}));

export const ExpandedList = styled(List)`
  margin-left: 12px;
`;

export const Separator = styledMui('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.notifications.item.borderBottom}`,
  width: '100%',
  margin: '12px 0',
}));

export const Title = styledMui('div')(({ theme, isHorizontal }) => ({
  padding: '0 16px 10px',
  color: theme.palette.text.title,
  ...(isHorizontal && {
    padding: '10px 10px 10px 0',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 14,
    lineHeight: '19px',
    letterSpacing: '0.06em',
    textTransform: 'uppercase',
  }),
}));

export const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  > :last-child {
    margin: 0 8px;
  }
`;

export const TooltipBlock = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;
