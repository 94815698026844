import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const StyledMap = styledMui('div')(({ theme, isGlobal }) => {
  const isGlobalWidget = isGlobal
    ? {
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.25,
      color: theme.palette.text.title,
    }
    : {
      fontFamily: 'Graphik LC',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '20px',
      letterSpacing: 0.16,
      color: theme.palette.text.main,
    };

  return {
    '&.esri-view': {
      minHeight: 370,
      height: 500,
    },
    'div:focus': {
      outline: 'none',
    },
    minHeight: 370,
    height: 500,
    width: '100%',
    '.esri-view-surface': {
      ...(isGlobal ? {} : { borderRadius: 4 }),
    },
    '.esri-widget': {
      backgroundColor: theme.palette.table.header.background,
      borderRadius: 4,
      color: theme.palette.text.light,
    },
    '&& .esri-popup__main-container': {
      maxWidth: 350,
      '.esri-popup__action': {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        fontFamily: 'Graphik LC',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 13,
        lineHeight: '20px',
        letterSpacing: 0.16,
        color: theme.palette.button.primary.color,
        '&:hover': {
          color: theme.palette.button.primary.hover,
          backgroundColor: 'unset',
        },
      },
      '.esri-popup__header': {
        '.esri-popup__header-container': {
          '.esri-widget__heading': isGlobalWidget,
          '&:hover': {
            backgroundColor: theme.palette.table.header.background,
          },
        },
      },
      '.esri-popup__content': {
        fontFamily: 'Graphik LC',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
        letterSpacing: 0.16,
        color: theme.palette.sidebar.selected.icon.background,
        '--calcite-ui-text-2': theme.palette.sidebar.selected.icon.background,
        '--calcite-ui-background': theme.palette.table.header.background,
      },
      '.esri-popup__button': {
        color: theme.palette.button.widget.color,
      },
      '.esri-feature-content': {
        fontFamily: 'Graphik LC',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 13,
        letterSpacing: 0.16,
        color: theme.palette.text.light,
        a: {
          fontFamily: 'Graphik LC',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 14,
          lineHeight: '24px',
          letterSpacing: 0.15,
          color: theme.palette.sidebar.selected.icon.background,
          textDecoration: 'none',
          '&:hover': {
            color: theme.palette.button.primary.hover,
          },
        },
      },
      '.esri-popup__header-title:hover': {
        backgroundColor: theme.palette.table.header.background,
      },
    },
    '.esri-widget__heading': {
      color: theme.palette.text.main,
    },
    '.esri-legend__service-label': {
      display: 'none',
    },
    '.esri-legend__layer-caption': {
      display: 'none',
    },
    '.esri-ui-corner .esri-component': {
      boxShadow: 'none',
    },
    '.esri-zoom': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '.esri-widget--button:first-of-type': {
        marginBottom: 3,
      },
    },
    '.esri-widget--button': {
      background: 'rgba(155, 161, 191, 0.6)',
      borderRadius: 2,
      color: theme.palette.button.widget.color,
      span: {
        fontWeight: 'bold',
      },
    },
    '.esri-expand.esri-widget': {
      background: 'transparent',
    },
    '&& .esri-expand .esri-widget--panel': {
      maxHeight: 430,
    },
    '&& .esri-expand__content': {
      top: 37,
      right: -7,
    },
    '&& .esri-popup__pointer': {
      display: 'none',
    },
    '.esri-popup__navigation': {
      background: 'none',
    },
    '.marker': {
      flexGrow: 0,
      height: 8,
      width: 8,
      borderRadius: 8,
    },
    '.marker-sq': {
      flexGrow: 0,
      height: 10,
      width: 10,
      borderRadius: 1,
    },
    '.row': {
      display: 'flex',
      alignItems: 'center',
      ':not(:last-child)': {
        marginBottom: 10,
      },
      '>:not(:last-child)': {
        marginRight: 10,
      },
      '.data': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        fontFamily: 'Graphik LC',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        letterSpacing: 0.25,
        color: theme.palette.text.main,
        '>:last-child': {
          color: theme.palette.title,
          fontWeight: 500,
        },
        '.no-data': {
          color: theme.palette.text.light,
        },
      },
    },
    '.risk-score': {
      display: 'flex',
      width: '100%',
      marginTop: 13,
      paddingTop: 11,
      borderTop: `1px solid ${theme.palette.notifications.item.borderBottom}`,
      fontFamily: 'Graphik LC',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      letterSpacing: 0.25,
      lineHeight: '20px',
      color: theme.palette.text.main,
      '>*': {
        display: 'flex',
        alignItems: 'center',
        '>:not(:last-child)': {
          marginRight: 10,
        },
      },
      '>:not(:last-child)': {
        marginRight: 10,
        paddingRight: 10,
        borderRight: `1px solid ${theme.palette.notifications.item.borderBottom}`,
      },
      '>:first-of-type': {
        color: theme.palette.title,
        fontWeight: 500,
      },
    },
  };
});

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ $querying }) => $querying && 'cursor: progress;'}
`;
