import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import { Button } from 'components';

export const Text = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontSize: 14,
  lineHeight: 20,
  letterSpacing: 0.16,
  color: theme.palette.text.main,
}));

export const InfoWrapper = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 20px',
  margin: '15px 0',
  background: theme.palette.notification.info.background,
  borderRadius: 4,
}));

export const StyledButton = styled(Button)`
  &&& {
    min-width: 0;
    min-height: 0;
    font-family: Graphik LC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
`;
