import styled from 'styled-components';
import {styled as styledMui} from '@mui/material/styles';

export const NotificationsBackdrop = styledMui("div")(({ theme, animations }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: theme.palette.backdrop,
  zIndex: 1000,
}));

export const NotificationsRoot = styledMui('div')(({ theme }) => ({
  position: 'absolute',
  top: 74,
  left: 'auto',
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  width: 416,
  background: theme.palette.notifications.color,
  borderRadius: 4,
  boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
}));

export const Title = styledMui('div')(({ theme }) => ({
  marginTop: 16,
  marginBottom: 5,
  paddingLeft: 24,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: 22,
  lineHeight: '150%',
  color: theme.palette.text.main,
}));

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const Tabs = styled.div`
  display: flex;
  padding-left: 24px;
  margin-bottom: 20px;
`;

export const SelectedBorder = styledMui('div')(({ theme }) => ({
  width: 0,
  marginTop: 8,
  borderBottom: `3px solid ${theme.palette.sidebar.selected.icon.background}`,
  transition: 'width 300ms ease-out 0ms',
}));

export const Tab = styledMui('div')(({ theme, selected }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: 110,
  marginRight: 16,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 13,
  lineHeight: '150%',
  color: theme.palette.text.light,
  ...(selected && {
    color: theme.palette.navbar.tab.active,
    fontWeight: 500,
    '> div': {
      width: '100%',
    },
  }),
}));

export const DayGroup = styled.div`
  position: relative;
`;

export const DayTitle = styledMui('div')(({ theme }) => ({
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.notifications.dayTitle,
  color: theme.palette.text.light,
}));

