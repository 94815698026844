import axios from 'axios';
import qs from 'qs';
import FileDownload from 'js-file-download';
import ApiClient from 'services/ApiClient';
import { formatSortField, getFileType } from 'utils/FormatUtils';

const PAGE_SIZE = 10;

class DocumentService extends ApiClient {
  // FOLDERS

  getFolders(projectId) {
    const query = {
      ProjectId: projectId,
    };

    return this.call({
      method: 'get',
      url: `/folders?${qs.stringify(query)}`,
      headers: { 'Client-Type': 'mobile' }, // will be removed after BE adjustment
    });
  }

  addFolder({ projectId, parentFolderId, name }) {
    return this.call({
      method: 'post',
      url: '/folders',
      data: {
        projectId,
        parentFolderId,
        name,
      },
    });
  }

  renameFolder({ id, name }) {
    return this.call({
      method: 'put',
      url: '/folders/rename',
      data: {
        id,
        name,
      },
    });
  }

  deleteFolder(folderId) {
    return this.call({
      method: 'delete',
      url: `/folders/${folderId}`,
    });
  }

  moveFolder(folderId, targetFolderId) {
    return this.call({
      method: 'put',
      url: '/folders/move',
      data: {
        id: folderId,
        targetFolderId,
      },
    });
  }

  // FILES

  moveFiles(fileIds, targetFolderId) {
    return this.call({
      method: 'put',
      url: '/files/move',
      data: {
        ids: [...fileIds],
        folderId: targetFolderId,
      },
    });
  }

  renameFile(id, name, referenceId = '') {
    return this.call({
      method: 'put',
      url: '/files/rename',
      data: {
        id,
        name,
        referenceId,
      },
    });
  }

  getFiles({ folderId, page = 1, pageSize = PAGE_SIZE, sortBy, searchFilter }) {
    const query = {
      FolderId: folderId,
      Page: page,
      PageSize: pageSize,
    };

    if (sortBy && sortBy.field && sortBy.direction !== 'None') {
      query.SortFieldName = formatSortField(sortBy.field);
      query.SortDirection = sortBy.direction;
    }

    if (searchFilter) {
      query.Name = searchFilter;
    }

    return this.call({
      method: 'get',
      url: `/files?${qs.stringify(query)}`,
    });
  }

  getDevicesLayouts(projectId) {
    const query = {
      ProjectId: projectId,
    };

    // return this.call({
    //   method: 'get',
    //   url: `/folders?${qs.stringify(query)}`,
    //   headers: { 'Client-Type': 'mobile' }, // will be removed after BE adjustment
    // });
    return this.call({
      method: 'get',
      url: `/files/iot-devices-layouts?${qs.stringify(query)}`,
    });
  }

  deleteFiles(fileIds) {
    return this.call({
      method: 'delete',
      url: '/files',
      data: {
        ids: fileIds,
      },
    });
  }

  updateVisibility(ids, isPrivate) {
    return this.call({
      method: 'put',
      url: '/files/update-visibility',
      data: {
        ids,
        isPrivate,
      },
    });
  }

  downloadFile(url, name, pdfViewer, progressHandler, info, isPdfDownload) {
    const onDownloadProgress = progressHandler ? (event) => progressHandler(event, info) : undefined;

    return axios({
      method: 'get',
      url,
      responseType: 'blob',
      onDownloadProgress,
    }).then((response) => {
      if (pdfViewer && getFileType(name) === 'pdf') {
        if (isPdfDownload) {
          FileDownload(response.data, name);
        }
        // Create a Blob from the PDF Stream
        const fileBlob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(fileBlob);

        return fileURL;
      }
      FileDownload(response.data, name);

      return null;
    });
  }

  getUploadFileUrls(folderId, fileNames) {
    const query = {
      FolderId: folderId,
      FileNames: fileNames,
    };

    return this.call({
      method: 'get',
      url: `/files/upload-urls?${qs.stringify(query)}`,
    });
  }

  async getUploadFileUrl(folderId, fileName) {
    const { data: { data } } = await this.getUploadFileUrls(folderId, [fileName]);

    return data[0];
  }

  uploadFileToAWS(file, url, onUploadProgress) {
    return axios({
      method: 'PUT',
      url,
      data: file,
      onUploadProgress,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  storeFilesMetadata(folderId, filesMetadata) {
    return this.call({
      method: 'post',
      url: '/files/store-metadata',
      data: {
        folderId,
        filesMetadata,
      },
    });
  }

  markAsOpened(id) {
    return this.call({
      method: 'put',
      url: '/files/mark-as-opened',
      data: {
        id,
      },
    });
  }

  async storeFileMetadata(folderId, s3Key, name) {
    const fileMetadata = { s3Key, name };
    const { data: { data } } = await this.storeFilesMetadata(folderId, [fileMetadata]);

    return data[0];
  }

  uploadPdfToLayouts(folderId, file, onUploadProgress) {
    const formDataFile = new FormData();

    formDataFile.append('folderId', folderId);
    if (Array.isArray(file)) {
      file.forEach((f) => formDataFile.append('files', f));
    } else {
      formDataFile.append('files', file);
    }

    return this.call({
      method: 'POST',
      url: '/files/upload-layouts',
      data: formDataFile,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }
}

export default new DocumentService();
