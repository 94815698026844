import { dashboard } from 'services/permissions/dashboard';
import { globalEcosystem } from 'services/permissions/globalEcosystem';
import { projects } from 'services/permissions/projects';
import { CompanyTypeSideBar, sidebar } from 'services/permissions/sidebar';
import { myCompany } from 'services/permissions/myCompany';
import { customReports } from 'services/permissions/customReports';
import { subscriptions } from 'services/permissions/subscriptions';
import { communications } from 'services/permissions/communications';
import { help } from 'services/permissions/help';
import { useLocation } from 'react-router-dom';
import { NameEmail, Nullable } from 'types';
import { RoleIdName, User, ROLE } from 'types/user';
import { COMPANY_TYPES } from 'types/company';

const CHECK_EXPIRATION_DELTA = 120000; // 2 mins

const USER_AUTH = 'user';
const EXPIRATION_TIME = 'exp';
const TOKEN_REFRESHING = 'tokenRefreshing';
const USER_NAME = 'name';
const USER_EMAIL = 'email';
const USER_ROLE = 'role';
const COMPANY_TYPE = 'companyType';
const COMPANY_ID = 'companyId';
const REMEMBERED_EMAIL = 'rememberedEmail';
const DASHBOARD_SETTINGS = 'dashboardSettings';
const ONBOARD_SETTINGS = 'onboardSettings';
const REPORT_NOTES_WARNING_1_3 = 'reportNotesWarning1.3';
const SUBSCRIPTION = 'subscription';
const THEME = 'theme';
const ACTIVE_TIME = 'activeTime';
const MODAL_STATE = 'modalState';
const HAS_ACCEPTED_LICENSE = 'hasAcceptedLicense';
const HAS_ACCEPTED_EULA = 'hasAcceptedEula';
const PRINCIPAL_COMPANY_ID_TYPES = 'principalCompanyIdTypes';
const COMPANY_PROJECT_TAB_ACCESS = 'companyProjectTabAccess';

const AUTH_PATH = '/account/signin';

interface OnBoardConfig {
  common: boolean;
  dashboard: boolean;
  projects: boolean;
  globalEcosystem: boolean;
  myCompany: boolean;
  help: boolean;
  communications: boolean;
}

// interface CompanyTypes {
//   insurance: string;
//   risk: string;
//   noCompany: string;
// }

interface RolesMap {
  [key: string]: string;
  [key: number]: string;
}

// https://paralect.atlassian.net/wiki/spaces/IMITGR/pages/2397732923/Roles+-+Name+Mapping
export const ROLES_LIST: RoleIdName[] = [
  {
    id: ROLE.ADMIN,
    name: 'Super Admin',
    brokerCompanyName: 'Super Admin',
  },
  {
    id: ROLE.IC_COORDINATOR,
    name: 'Coordinator',
    brokerCompanyName: 'Coordinator',
  },
  {
    id: ROLE.UNDERWRITER,
    name: 'Underwriter',
    brokerCompanyName: 'Client Account Handler',
  },
  {
    id: ROLE.BROKER,
    name: 'Broker',
    brokerCompanyName: 'Broker',
  },
  {
    id: ROLE.INSURANCE_REPRESENTATIVE,
    name: 'Insurance Representative',
    brokerCompanyName: 'Insurance Representative',
  },
  {
    id: ROLE.RISK_MANAGER,
    name: 'Risk Manager',
    brokerCompanyName: 'Client Risk Manager',
  },
  {
    id: ROLE.DEVELOPER_OWNER,
    name: 'Developer Owner',
    brokerCompanyName: 'Developer Owner',
  },
  {
    id: ROLE.SITE_CONTACT,
    name: 'Site Contact',
    brokerCompanyName: 'Property Respondent',
  },
  {
    id: ROLE.RC_COORDINATOR,
    name: 'Coordinator',
    brokerCompanyName: 'Coordinator',
  },
  {
    id: ROLE.RISK_ENGINEER,
    name: 'Risk Engineer',
    brokerCompanyName: 'Risk Engineer',
  },
  {
    id: ROLE.NONE,
    name: 'None',
    brokerCompanyName: 'None',
  },
];

const ONBOARD_CONFIG: OnBoardConfig = {
  common: false,
  dashboard: false,
  projects: false,
  globalEcosystem: false,
  myCompany: false,
  help: false,
  communications: false,
};

const SKIPPED_ONBOARD_CONFIG: OnBoardConfig = {
  common: true,
  dashboard: true,
  projects: true,
  globalEcosystem: true,
  myCompany: true,
  help: true,
  communications: true,
};

export const ROLES_IDS: RolesMap = ROLES_LIST.reduce((result: RolesMap, role) => {
  result[role.id] = role.id;

  return result;
}, {});

// Example for Refresh Token implementation
// https://github.com/zmts/beauty-vuejs-boilerplate/blob/master/src/services/http.init.js
// https://github.com/zmts/beauty-vuejs-boilerplate/blob/master/src/services/auth.service.js

class AuthService {
  static isLocalDevOrDemo(url: string): boolean {
    return ['localhost', 'demo', 'staging'].some((t) => url.includes(t));
  }

  static getToken(): Nullable<string> {
    const accessToken = localStorage.getItem(USER_AUTH);

    return accessToken;
  }

  static getHasAcceptedLicense(): boolean {
    const hasAcceptedLicense = localStorage.getItem(HAS_ACCEPTED_LICENSE);

    return Boolean(hasAcceptedLicense);
  }

  static getHasAcceptedEula(): boolean {
    const hasAcceptedLicense = localStorage.getItem(HAS_ACCEPTED_EULA);

    return Boolean(hasAcceptedLicense);
  }

  static getRoleId(): string {
    return localStorage.getItem(USER_ROLE) || '';
  }

  static getUserSubscription(): string {
    return localStorage.getItem(SUBSCRIPTION) || '';
  }

  static getUserData(): NameEmail {
    return {
      name: localStorage.getItem(USER_NAME) || '',
      email: localStorage.getItem(USER_EMAIL) || '',
    };
  }

  static getCompanyProjectTabAccess(): string[] {
    const tabs = localStorage.getItem(COMPANY_PROJECT_TAB_ACCESS);

    if (tabs === 'undefined') {
      return [];
    }

    // const parsed: { [key: string]: string[] } = JSON.parse(tabs || '{}');
    // return parsed[companyId] || [];
    return tabs ? JSON.parse(tabs) : [];
  }

  static getCompanyType(companyId?: string): COMPANY_TYPES {
    if (companyId == null) {
      const companyType = localStorage.getItem(COMPANY_TYPE);

      return companyType as COMPANY_TYPES;
    }
    const companyTypeIds = JSON.parse(localStorage.getItem(PRINCIPAL_COMPANY_ID_TYPES) || '{}');
    const cType = companyTypeIds[companyId] as COMPANY_TYPES;

    if (cType == null) {
      const companyType = localStorage.getItem(COMPANY_TYPE);

      return companyType as COMPANY_TYPES;
    }

    return cType;
  }

  static getCompanyId(): string {
    return localStorage.getItem(COMPANY_ID) || '';
  }

  static acceptLicense(): void {
    localStorage.setItem(HAS_ACCEPTED_LICENSE, 'true');
  }

  static acceptEula(): void {
    localStorage.setItem(HAS_ACCEPTED_EULA, 'true');
  }

  static setToken(accessToken: string): void {
    localStorage.setItem(USER_AUTH, accessToken);
    const payload = accessToken.split('.')[1];
    const tokenData = JSON.parse(atob(payload));
    const {
      urole,
      exp,
      uemail,
      ctype,
      cid,
      fname = '',
      lname = '',
      sp = 'FreeUsage',
      hal = false,
      haeula = false,
      pctaccess,
      pctype,
    } = tokenData;

    localStorage.setItem(USER_ROLE, urole);
    localStorage.setItem(EXPIRATION_TIME, (exp * 1000).toString());
    localStorage.setItem(TOKEN_REFRESHING, 'false');
    localStorage.setItem(USER_NAME, `${fname} ${lname}`);
    localStorage.setItem(USER_EMAIL, `${uemail}`);
    localStorage.setItem(COMPANY_TYPE, `${ctype}`);
    localStorage.setItem(COMPANY_ID, `${cid}`);
    localStorage.setItem(SUBSCRIPTION, `${sp}`);
    localStorage.setItem(MODAL_STATE, 'closed');
    localStorage.setItem(HAS_ACCEPTED_LICENSE, `${hal}`);
    localStorage.setItem(HAS_ACCEPTED_EULA, `${haeula}`);
    localStorage.setItem(COMPANY_PROJECT_TAB_ACCESS, `${pctaccess}`);
    localStorage.setItem(PRINCIPAL_COMPANY_ID_TYPES, `${pctype}`);

    const theme = localStorage.getItem(THEME);
    const onboardConfig = localStorage.getItem(ONBOARD_SETTINGS);

    if (!onboardConfig) {
      localStorage.setItem(ONBOARD_SETTINGS, JSON.stringify(ONBOARD_CONFIG));
    }
    if (!theme) {
      localStorage.setItem(THEME, 'dark');
    }
  }

  static isAccessTokenExpired(): boolean {
    const expirationTime = JSON.parse(
      localStorage.getItem(EXPIRATION_TIME) || 'null',
    );

    if (!expirationTime) return expirationTime;
    const timeBeforeExpiration = expirationTime - Date.now();

    return timeBeforeExpiration < CHECK_EXPIRATION_DELTA;
  }

  static setTokenIsRefreshing(value: string) {
    localStorage.setItem(TOKEN_REFRESHING, value);
  }

  static setCompanyProjectTabAccess(tabs: string[]) {
    const parsed = JSON.stringify(tabs);

    localStorage.setItem(COMPANY_PROJECT_TAB_ACCESS, `${parsed}`);
  }

  static getTokenIsRefreshing() {
    return JSON.parse(localStorage.getItem(TOKEN_REFRESHING) || 'false');
  }

  static resetToken() {
    localStorage.removeItem(USER_AUTH);
    localStorage.removeItem(USER_ROLE);
    localStorage.removeItem(EXPIRATION_TIME);
    localStorage.removeItem(TOKEN_REFRESHING);
    localStorage.removeItem(USER_NAME);
    localStorage.removeItem(USER_EMAIL);
    localStorage.removeItem(COMPANY_TYPE);
    localStorage.removeItem(COMPANY_ID);
    localStorage.removeItem(SUBSCRIPTION);
    localStorage.removeItem(MODAL_STATE);
    localStorage.removeItem(HAS_ACCEPTED_LICENSE);
    localStorage.removeItem(HAS_ACCEPTED_EULA);
    localStorage.removeItem(PRINCIPAL_COMPANY_ID_TYPES);
  }

  static signOut(): void {
    AuthService.resetToken();
    window.location.replace(AUTH_PATH);
  }

  static getUser(): User {
    const role = AuthService.getRoleId();
    const user = AuthService.getUserData();
    const companyType = AuthService.getCompanyType();
    const companyId = AuthService.getCompanyId();
    const subscription = AuthService.getUserSubscription();
    const hasAcceptedLicense = AuthService.getHasAcceptedLicense();
    const hasAcceptedEula = AuthService.getHasAcceptedEula();

    return { role, user, companyType, companyId, subscription, hasAcceptedLicense, hasAcceptedEula };
  }

  static getTheme() {
    return localStorage.getItem(THEME) || 'dark';
  }

  static toggleTheme(theme: any) {
    localStorage.setItem(THEME, theme);
  }

  static checkSectionVisit(section: any) {
    const onBoardConfig =
      JSON.parse(localStorage.getItem(ONBOARD_SETTINGS) || 'null') ||
      ONBOARD_CONFIG;

    if (section === 'dashboard' && !onBoardConfig.common) return true;

    return onBoardConfig[section];
  }

  static checkHasSeenReportNotesWarning() {
    return JSON.parse(localStorage.getItem(REPORT_NOTES_WARNING_1_3) || 'null');
  }

  static setHasSeenReportNotesWarning(value: boolean) {
    localStorage.setItem(REPORT_NOTES_WARNING_1_3, value.toString());
  }

  static updateOnboardConfig(visitedSectionName: string) {
    const onBoardConfig =
      JSON.parse(localStorage.getItem(ONBOARD_SETTINGS) || 'null') ||
      ONBOARD_CONFIG;

    localStorage.setItem(
      ONBOARD_SETTINGS,
      JSON.stringify({ ...onBoardConfig, [visitedSectionName]: true }),
    );
  }

  static skipOnboardConfig() {
    localStorage.setItem(
      ONBOARD_SETTINGS,
      JSON.stringify(SKIPPED_ONBOARD_CONFIG),
    );
  }

  static getOnboardingSections() {
    return (
      JSON.parse(localStorage.getItem(ONBOARD_SETTINGS) || 'null') ||
      ONBOARD_CONFIG
    );
  }

  static getPermissions(section: string, primaryRiskEmail = '') {
    const role = AuthService.getRoleId();
    const email = AuthService.getUserData()?.email;
    const location = useLocation();

    const tab = location.pathname.split('/').reverse()[0];

    switch (section) {
      case 'sidebar':
        return sidebar[tab][role];
      case 'projects':
        if (
          role === ROLES_IDS.RiskEngineer &&
          primaryRiskEmail !== email &&
          tab === 'report-notes'
        ) {
          return undefined;
        }
        if (role === ROLES_IDS.RiskEngineer && primaryRiskEmail !== '' && primaryRiskEmail !== email) {
          return {
            create: false,
            read: true,
            update: false,
            delete: false,
          };
        }

        return projects[tab] && projects[tab][role];
      case 'globalEcosystem':
        return globalEcosystem[tab][role];
      case 'myCompany':
        return myCompany[tab][role];
      case 'dashboard':
        return dashboard[tab][role];
      case 'customReports':
        return customReports[tab][role];
      case 'subscriptions':
        return subscriptions[tab][role];
      case 'nonSubscribers':
        return subscriptions[tab][role];
      case 'communications':
        return communications[tab][role];
      case 'help':
        return help[tab][role];
      default:
        return undefined;
    }
  }

  static getRolesForSection(permission: string, section: string) {
    switch (section) {
      case 'sidebar':
        return Object.keys(sidebar[permission]);
      case 'projects':
        return Object.keys(projects[permission]);
      case 'globalEcosystem':
        return Object.keys(globalEcosystem[permission]);
      case 'myCompany':
        return Object.keys(myCompany[permission]);
      case 'dashboard':
        return Object.keys(dashboard[permission]);
      case 'customReports':
        return Object.keys(customReports[permission]);
      case 'subscriptions':
        return Object.keys(subscriptions[permission]);
      case 'nonSubscribers':
        return Object.keys(subscriptions[permission]);
      case 'communications':
        return Object.keys(communications[permission]);
      case 'help':
        return Object.keys(help[permission]);
      default:
        return [];
    }
  }

  static getCompaniesForSection(permission: string, section: string): COMPANY_TYPES[] {
    switch (section) {
      case 'sidebar':
        return CompanyTypeSideBar[permission] ?? [];
      default:
        return [
          COMPANY_TYPES.BROKER,
          COMPANY_TYPES.INSURANCE,
          COMPANY_TYPES.RISK,
          COMPANY_TYPES.NO_COMPANY,
        ];
    }
  }

  static hasPermission(permission: string, section: string, tabs: string[] = []) {
    if (section === 'projects' && (tabs == null || tabs.length === 0)) {
      tabs = this.getCompanyProjectTabAccess();
    }
    const authorizedRoles = AuthService.getRolesForSection(permission, section);
    const authorizedCompanies = AuthService.getCompaniesForSection(permission, section);

    const roleId = AuthService.getRoleId();
    const companyType = AuthService.getCompanyType();
    const permitted = authorizedRoles.includes(roleId) || tabs.includes(permission);
    const permittedCompany = authorizedCompanies.includes(companyType);

    return permitted && permittedCompany;
  }

  static rememberEmail(email: string) {
    localStorage.setItem(REMEMBERED_EMAIL, String(email));
  }

  static getRememberedEmail(): { email: string } {
    return {
      email: localStorage.getItem(REMEMBERED_EMAIL) || '',
    };
  }

  static setModalState(value: string) {
    localStorage.setItem(MODAL_STATE, value);
  }

  static getModalState() {
    return localStorage.getItem(MODAL_STATE);
  }

  static setActiveTime() {
    localStorage.setItem(ACTIVE_TIME, Date.now().toString());
  }

  static getActiveTime() {
    return localStorage.getItem(ACTIVE_TIME);
  }

  static setDashboardSettings(settings: any) {
    localStorage.setItem(DASHBOARD_SETTINGS, JSON.stringify(settings));
  }

  static getDashboardSettings() {
    const value = localStorage.getItem(DASHBOARD_SETTINGS);

    if (value) {
      return JSON.parse(value);
    }

    return null;
  }
}

export const ROLES: RolesMap = ROLES_LIST.reduce((result: RolesMap, role) => {
  if (AuthService.getCompanyType() === COMPANY_TYPES.BROKER) {
    result[role.id] = role.brokerCompanyName;
  } else {
    result[role.id] = role.name;
  }

  return result;
}, {});

export default AuthService;
