import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import ChevronUp from 'components/Icon/Store/ui/ChevronUp';
import { LinearProgress } from '@mui/material';

export const ScrollBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 12px;
  /* for scroll to start of all page not only header */
  margin-right: 220px;
  padding-top: 245px;
  margin-top: -245px;
`;

export const ContactUsPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 12px;
`;

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 12px;
`;

export const Title = styledMui('div')(({ theme }) => ({
  margin: 0,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: 24,
  lineHeight: '33px',
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  textTransform: 'none',
}));

export const Text = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 16,
  lineHeight: '138.5%',
  color: theme.palette.text.light,
  whiteSpace: 'nowrap',
  textTransform: 'none',
}));

export const ButtonContainer = styledMui('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: 16,
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '138.5%',
  color: theme.palette.text.title,
  whiteSpace: 'nowrap',
  textTransform: 'none',
  '> div': {
    marginLeft: 20,
  },
}));

export const Root = styled.div`
  display: flex;
  min-width: 1000px;
`;

export const TitleButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 38px 0 0;
  cursor: pointer;
`;

export const TitleText = styledMui('span')(({ theme }) => ({
  color: theme.palette.text.main,
  textTransform: 'uppercase',
}));

export const Line = styledMui('span')(({ theme }) => ({
  display: 'flex',
  margin: '0 14px',
  width: '50%',
  flexGrow: 1,
  borderTop: `1px solid ${theme.palette.input.disabled.text}`,
}));

export const IconWrapper = styledMui('div')(({ theme, isOpened }) => ({
  display: 'flex',
  alignItems: 'center',
  transform: isOpened ? 'rotate(-180deg)' : 'none',
  transition: 'transform 0.5s',
  color: theme.palette.navbar.tab.color,
}));

export const ArrowUp = styledMui(ChevronUp)(({ theme }) => ({
  color: theme.palette.input.disabled.text,
}));

export const StyledLinearProgress = styled(LinearProgress)`
  width: 8%;
  margin-left: 10px;
  border-radius: 10px;
`;
